@import "./../common/var";

$padding: 30px;
$spacing: 30px;
$leftPanel: 320px;
$rightPanel: 380px;

$minWide: $padding + $leftPanel + $spacing + $rightPanel + $padding;

.backBtn {
	color: $accentColor;
	text-decoration: none;
	font-size: 16px;
	font-weight: 400;
	display: inline-flex;
	align-items: center;
	height: 50px;
	margin-top: 10px;
	margin-left: 10px;
	margin-bottom: 30px;

	& > svg {
		margin: 0 10px;
	}
}

.title {
	font-size: 32px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 30px;
}

.wrapper {
	padding: $padding;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media screen and (min-width: $minWide) {
		align-items: flex-start;
		flex-direction: row;
	}

	// get horizontal padding to 20px at 320 screen.
	// will transition to padding 30px smoothly at 500 screen
	// because 500 * 6% = 30
	@media screen and (max-width: 500px) {
		padding: $padding 6%;
	}
}

.summary {
	flex: 1;
	width: 100%;
	min-width: auto;
	max-width: 480px;
	margin-bottom: $spacing;
	margin-right: 0;

	@media screen and (min-width: $minWide) {
		min-width: $leftPanel;
		margin-bottom: 0;
		margin-right: $spacing;
	}
}

.thumbnail {
	background-size: cover;
	background-position: center;
	width: 100%;
	padding-top: 55%;
	border-radius: 10px;
	margin-bottom: 50px;
	@include shadow;
}

.content {
	flex: 2;
	min-width: auto;
	max-width: 600px;

	@media screen and (min-width: $minWide) {
		min-width: $rightPanel;
	}

	& > article {
		line-height: 2;

		img {
			width: 100%;
			margin-top: 10px;
			margin-bottom: 15px;
		}

		h1 {
			font-size: 32px;
			font-weight: 700;
			margin-bottom: 15px;
		}

		h2 {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 15px;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 10px;
		}
	}
}
