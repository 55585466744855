@import "./common/var";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

* {
	box-sizing: border-box;
}

body {
	line-height: 1.2;
	font-family: "Inter", Arial;
	margin: 0;
	background-color: $backgroundOnLight;
	color: $colorOnLight;

	@media (prefers-color-scheme: dark) {
		background-color: $backgroundOnDark;
		color: $colorOnDark;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}
