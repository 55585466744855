@import "./../../common/var";

.summaryPoint {
	display: flex;
	margin-bottom: 30px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	min-width: 40px;
	height: 40px;
	border-radius: 40px;
	background-color: $accentColor;
	@include whiteColor;
	margin-right: 20px;
}

.content {
	line-height: 1.5;

	& > h4 {
		font-size: 16px;
		font-weight: 400;
		margin-bottom: 5px;
	}

	p {
		opacity: 0.7;
		font-size: 14px;
		font-weight: 400;
		margin-left: 10px;
		margin-bottom: 5px;

		&:last-child {
			margin-bottom: 0;
		}

		& > a {
			color: $accentColor;
		}
	}
}
