.wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;

	& > div {
		padding: 20px;
		max-width: 500px;
		line-height: 2;

		& > h4 {
			margin-bottom: 30px;
		}

		& > p {
			margin-bottom: 20px;
		}

		& > blockquote {
			margin-left: 20px;
		}
	}
}

.buttons {
	padding-top: 20px;
}