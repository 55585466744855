.works {
	display: flex;
	flex-direction: column;

	& > h3 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 30px - 15px; // match with #worksGallery > .project
		text-align: center;
	}
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
