@import '../../common/var';

.project {
	display: flex;
	flex-direction: column;
	margin: 15px 10px;
	color: inherit;
	text-decoration: none;

	& > img {
		width: 260px;
		height: 150px;
		object-fit: cover;
		border-radius: 10px;
		margin-bottom: 15px;
		@include shadow;
	}

	& > h4 {
		font-size: 16px;
		font-weight: 400;
		text-align: center;
	}
}
