@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
*{box-sizing:border-box}body{line-height:1.2;font-family:"Inter",Arial;margin:0;background-color:#fff;color:#212121}@media(prefers-color-scheme: dark){body{background-color:#212121;color:#ccc}}h1,h2,h3,h4,h5,h6,p{margin:0}
.Home_home__1HM34{min-height:100vh;display:flex;flex-direction:column;justify-content:center;padding:50px 20px}.Home_home__1HM34>div{display:flex;flex-direction:column;align-items:center}
.WhoAmI_whoami__2T1-W{margin-bottom:90px}.WhoAmI_profile__UV0cD{display:flex;flex-wrap:wrap;align-items:center;margin-bottom:30px}.WhoAmI_profile__UV0cD>img{border-radius:96px;width:96px;height:96px;margin-right:30px;margin-bottom:20px}.WhoAmI_profile__UV0cD>div{margin-bottom:20px}.WhoAmI_profile__UV0cD>div>h1{font-size:24px;font-weight:700;margin-bottom:10px}.WhoAmI_profile__UV0cD>div>h2{font-size:18px;font-weight:400}.WhoAmI_links__3F3PC{display:flex;flex-direction:column;align-items:stretch}
.ProfileLink_profileLink__8foYI{display:flex;align-items:center;border-radius:50px;padding:10px 20px 10px 5px;font-size:16px;text-decoration:none;color:#fff;margin-bottom:20px;background-color:#1e88e5}@media(prefers-color-scheme: dark){.ProfileLink_profileLink__8foYI{color:#ddd}}.ProfileLink_profileLink__8foYI:last-child{margin-bottom:0}.ProfileLink_profileLink__8foYI>i{width:50px;height:30px;display:flex;align-items:center;justify-content:center}.ProfileLink_profileLink__8foYI[data-variant=github]{background-color:#24292e}.ProfileLink_profileLink__8foYI[data-variant=linkedin]{background-color:#0077b5}.ProfileLink_profileLink__8foYI[data-variant=google-play]{background-color:#000}.ProfileLink_profileLink__8foYI[data-variant=muted]{background-color:#eee;color:#212121}@media(prefers-color-scheme: dark){.ProfileLink_profileLink__8foYI[data-variant=muted]{background-color:#424242;color:#ccc}}
.Works_works__3G6mO{display:flex;flex-direction:column}.Works_works__3G6mO>h3{font-size:20px;font-weight:700;margin-bottom:15px;text-align:center}.Works_gallery__1eDoM{display:flex;flex-wrap:wrap;justify-content:center}
.ProjectThumb_project__1P-qN{display:flex;flex-direction:column;margin:15px 10px;color:inherit;text-decoration:none}.ProjectThumb_project__1P-qN>img{width:260px;height:150px;object-fit:cover;border-radius:10px;margin-bottom:15px;box-shadow:2px 2px 8px rgba(0,0,0,.1)}@media(prefers-color-scheme: dark){.ProjectThumb_project__1P-qN>img{box-shadow:2px 2px 8px rgba(0,0,0,.2)}}.ProjectThumb_project__1P-qN>h4{font-size:16px;font-weight:400;text-align:center}
.Project_backBtn__2B_vJ{color:#1e88e5;text-decoration:none;font-size:16px;font-weight:400;display:inline-flex;align-items:center;height:50px;margin-top:10px;margin-left:10px;margin-bottom:30px}.Project_backBtn__2B_vJ>svg{margin:0 10px}.Project_title__3LbTi{font-size:32px;font-weight:700;text-align:center;margin-bottom:30px}.Project_wrapper__l2Rxi{padding:30px;display:flex;justify-content:center;align-items:center;flex-direction:column}@media screen and (min-width: 790px){.Project_wrapper__l2Rxi{align-items:flex-start;flex-direction:row}}@media screen and (max-width: 500px){.Project_wrapper__l2Rxi{padding:30px 6%}}.Project_summary__3v2ZH{flex:1 1;width:100%;min-width:auto;max-width:480px;margin-bottom:30px;margin-right:0}@media screen and (min-width: 790px){.Project_summary__3v2ZH{min-width:320px;margin-bottom:0;margin-right:30px}}.Project_thumbnail__1ema0{background-size:cover;background-position:center;width:100%;padding-top:55%;border-radius:10px;margin-bottom:50px;box-shadow:2px 2px 8px rgba(0,0,0,.1)}@media(prefers-color-scheme: dark){.Project_thumbnail__1ema0{box-shadow:2px 2px 8px rgba(0,0,0,.2)}}.Project_content__33QRX{flex:2 1;min-width:auto;max-width:600px}@media screen and (min-width: 790px){.Project_content__33QRX{min-width:380px}}.Project_content__33QRX>article{line-height:2}.Project_content__33QRX>article img{width:100%;margin-top:10px;margin-bottom:15px}.Project_content__33QRX>article h1{font-size:32px;font-weight:700;margin-bottom:15px}.Project_content__33QRX>article h2{font-size:24px;font-weight:700;margin-bottom:15px}.Project_content__33QRX>article p{font-size:16px;font-weight:400;margin-bottom:10px}
.SummaryPoint_summaryPoint__3I2dZ{display:flex;margin-bottom:30px}.SummaryPoint_icon__2jOdY{display:flex;align-items:center;justify-content:center;width:40px;min-width:40px;height:40px;border-radius:40px;background-color:#1e88e5;color:#fff;margin-right:20px}@media(prefers-color-scheme: dark){.SummaryPoint_icon__2jOdY{color:#ddd}}.SummaryPoint_content__2F0OF{line-height:1.5}.SummaryPoint_content__2F0OF>h4{font-size:16px;font-weight:400;margin-bottom:5px}.SummaryPoint_content__2F0OF p{opacity:.7;font-size:14px;font-weight:400;margin-left:10px;margin-bottom:5px}.SummaryPoint_content__2F0OF p:last-child{margin-bottom:0}.SummaryPoint_content__2F0OF p>a{color:#1e88e5}
.RequestResume_wrap__3o6Z6{display:flex;align-items:center;justify-content:center;height:100vh}.RequestResume_wrap__3o6Z6>div{padding:20px;max-width:500px;line-height:2}.RequestResume_wrap__3o6Z6>div>h4{margin-bottom:30px}.RequestResume_wrap__3o6Z6>div>p{margin-bottom:20px}.RequestResume_wrap__3o6Z6>div>blockquote{margin-left:20px}.RequestResume_buttons__3zif3{padding-top:20px}
