$backgroundOnLight: #fff;
$colorOnLight: #212121;

$backgroundOnDark: #212121;
$colorOnDark: #ccc;

$accentColor: #1e88e5;

@mixin whiteColor {
	color: #fff;

	@media (prefers-color-scheme: dark) {
		color: #ddd;
	}
}

@mixin shadow {
	box-shadow: 2px 2px 8px rgba(0, 0, 0, .1);

	@media (prefers-color-scheme: dark) {
		box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
	}
}
